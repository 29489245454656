@use "../async" as core;

.module.type-module_base_app-promotion {
  container-type: inline-size;
}

.app-promotion {
  --mockup-base-width: 320px;
  --mockup-mock-width: 368;
  --mockup-mock-height: 750;
  --mockup-mask-width: 336;
  --mockup-mask-height: 723;
  // --mockup-mask-image should be set from the template
  // --mockup-mock-image should be set from the template
  --mockup-image-left: calc((100 - (var(--mockup-mask-width) / var(--mockup-mock-width) * 100)) * 0.5%);
  --mockup-image-width: calc(100% - var(--mockup-image-left) - var(--mockup-image-left));
  --mockup-image-top: calc((100 - (var(--mockup-mask-height) / var(--mockup-mock-height) * 100)) * 0.5%);
  --mockup-image-height: calc((100% - var(--mockup-image-top) - var(--mockup-image-top)));
  @include core.named-container-breakpoint(large, up) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &--hide-mockup-in-small-containers {
    @include core.named-container-breakpoint(large, until) {
      .app-promotion__mockups-wrapper {
        display: none;
      }
    }
  }
  &--mockup-right-in-large-containers {
    @include core.named-container-breakpoint(large, up) {
      flex-direction: row-reverse;
    }
  }
  &__mockups-wrapper {
    flex: 0 0 50%;
    aspect-ratio: calc(var(--mockup-mock-width) * 4) / var(--mockup-mock-height);
    position: relative;
    margin-bottom: var(--space-big);
    overflow: hidden;
    border-bottom: 1px solid var(--color-decent-ui);
    .app-promotion__mockup {
      --mockup-mock-translate-y: -25%;
      --mockup-inactive-scale: .75;
      --mockup-inactive-filter: blur(3px);
      width: 50%;
      max-width: var(--mockup-base-width);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(var(--mockup-mock-translate-x), var(--mockup-mock-translate-y)) scale(var(--mockup-mock-scale));
      transition: transform .5s cubic-bezier(.3,-.5,.6,1.25), filter .5s ease, z-index 0s linear .25s;
      &[data-position="1"] {
        --mockup-mock-translate-x: -50%;
        --mockup-mock-scale: 1;
        filter: none;
        z-index: 2;
      }
      &[data-position="2"] {
        --mockup-mock-translate-x: -90%;
        --mockup-mock-scale: var(--mockup-inactive-scale);
        filter: var(--mockup-inactive-filter);
        z-index: 1;
      }
      &[data-position="3"] {
        --mockup-mock-translate-x: -10%;
        --mockup-mock-scale: var(--mockup-inactive-scale);
        filter: var(--mockup-inactive-filter);
        z-index: 1;
      }
    }
  }
  @include core.named-container-breakpoint(large, up) {
    .app-promotion__mockups-wrapper {
      aspect-ratio: calc(var(--mockup-mock-width) * 2) / var(--mockup-mock-height);
      margin-bottom: 0;
      border-bottom: none;
      overflow: visible;
      .app-promotion__mockup {
        --mockup-mock-translate-y: -50%;
      }
    }
  }
  @include core.named-container-breakpoint(large, until) {
    &[class*='app-promotion--mockup-position-'] {
      display: grid;
    }
    &.app-promotion--mockup-position-above-in-small-containers {
      .app-promotion__text { order: 2; }
      .app-promotion__mockups-wrapper { order: 1; }
    }
    &.app-promotion--mockup-position-below-in-small-containers {
      .app-promotion__text { order: 1; }
      .app-promotion__mockups-wrapper {
        order: 2;
        margin-bottom: 0;
      }
    }
  }
  &__text {
    flex: 0 1 100%;
  }
  &__mockup {
    display: inline-block;
    position: relative;
    z-index: 1;
    width: var(--mockup-base-width);
    aspect-ratio: var(--mockup-mock-width) / var(--mockup-mock-height);
    img {
      position: absolute;
      left: var(--mockup-image-left);
      top: var(--mockup-image-top);
      width: var(--mockup-image-width);
      height: var(--mockup-image-height);
      object-fit: cover;
      object-position: left top;
      z-index: 0;
      mask-image: var(--mockup-mask-image);
      mask-size: contain;
      mask-repeat: no-repeat;
    }
    &::before {
      content: "";
      background-size: contain;
      background-repeat: no-repeat;
      background-image: var(--mockup-mock-image);
      position: absolute;
      inset: 0;
      z-index: 1;
    }
  }
  &__links-header {
    font-size: var(--font-size-big);
    color: var(--color-contrast);
    &::after {
      content: ":";
    }
  }
  &__links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .base-rte-iconlist {
    --base-rte-iconlist-size: 1.5em;
    --base-rte-iconlist-offset-top: 0;
    --base-rte-iconlist-item-padding: .5em;
  }
}

.module.type-module_base_app-promotion {
  .base-header-group {
    text-align: center;
    --margin-b-space: var(--space-big);
  }
}
